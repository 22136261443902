import './PrintLabelsSectorWise.css';
import React, { Component } from 'react';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import requireAuth from '../../../requireAuth';
import { connect } from 'react-redux';
import * as adminReportsAction from '../../../reducers/adminReportsAction';
import * as scheduleAction from '../../../reducers/scheduleAction';
import {
  AppState,
  Schedule,
  PackageColor,
  PackageColorType,
  PackageType,
  SectorCount,
  PackageTypeColor
} from '../../../type/Type';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Spinner from '../../Spinner/Spinner';
import { Button, IconButton, TableFooter } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const dateFns = require('date-fns');

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 10,
    fontWeight: 'bold',
    padding: 8
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default
    }
  }
}))(TableRow);

const StyledIconButton = withStyles(theme => ({
  root: {
    display: 'inline-block',
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: 0,
    minWidth: 0
  }
}))(IconButton);

class PrintLabelSectorWise extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.getScheduleForSelectedDate = this.getScheduleForSelectedDate.bind(
      this
    );
  }

  getScheduleForSelectedDate = (selectedDate: string): Schedule => {
    return this.props.schedules.find(
      schedule => schedule.dailyDate === selectedDate
    ) as Schedule;
  };
  componentDidMount() {
    if (this.props.schedules.length == 0) {
      this.props.getMonthsSchedule();
    }
    const scheduleForToday = this.getScheduleForSelectedDate(
      dateFns.format(new Date(), 'yyyy-MM-dd', { awareOfUnicodeTokens: true })
    );
    this.setState({
      noMeal: scheduleForToday ? scheduleForToday.noMeal : false
    });
    this.props.getMealCountBySectorForSelectedDateV2(
      dateFns.format(new Date(), 'yyyy-MM-dd', { awareOfUnicodeTokens: true })
    );
  }

  render() {
    const sectorCounts: SectorCount[] = this.props.reportDailyThaliCount
      .sectorCounts;
    const totalCountPacakageType = {};
    let totalCount = 0;
    const buildTotalCountPacakageType = () =>
      Object.keys(PackageType).forEach((key: any) => {
        totalCountPacakageType[key] = 0;
      });
    buildTotalCountPacakageType();
    const buildSectorNameCell = (sectorKey: string) => {
      return (
        <StyledTableCell component="th" scope="row">
          {sectorKey}
        </StyledTableCell>
      );
    };

    const calculateSectorPackageCount = (
      sectorKey: any,
      packageTypeTiffinCountKey: any
    ) => {
      let sectorPackageCount = 0;
      sectorPackageCount =
        this.props.reportDailyThaliCount.sectorCounts[sectorKey]
          .packageTypeTiffinCount[packageTypeTiffinCountKey] &&
        this.props.reportDailyThaliCount.sectorCounts[sectorKey]
          .packageTypeTiffinCount[packageTypeTiffinCountKey].actualCount +
        this.props.reportDailyThaliCount.sectorCounts[sectorKey]
          .packageTypeTiffinCount[packageTypeTiffinCountKey].additionCount -
        this.props.reportDailyThaliCount.sectorCounts[sectorKey]
          .packageTypeTiffinCount[packageTypeTiffinCountKey]
          .cancellationCount;
      return sectorPackageCount;
    };

    const calculateTotalCount = (
      sectorPackageCount: number,
      sectorCount: number,
      packageTypeTiffinCountKey: any
    ) => {
      if (!isNaN(sectorPackageCount)) {
        sectorCount = sectorCount + sectorPackageCount;
        if (!isNaN(totalCountPacakageType[packageTypeTiffinCountKey])) {
          totalCountPacakageType[packageTypeTiffinCountKey] =
            totalCountPacakageType[packageTypeTiffinCountKey] +
            sectorPackageCount;
        } else {
          totalCountPacakageType[
            packageTypeTiffinCountKey
          ] = sectorPackageCount;
        }
      }
      return sectorCount;
    };

    const generatePrintableLabels = overrideDetails => {
      overrideDetails = overrideDetails.sort((a, b) => {
        const packageOrder = { Single: 0, Medium: 1, Regular: 2 };
        // For REGULAR and ADD types, sort by packageType first
        const packageComparison = packageOrder[a.packageType] - packageOrder[b.packageType];

        if (packageComparison !== 0) {
          return packageComparison;
        } else {
          // If packageType is the same, sort by firstName and then lastName
          const nameComparison = (a.firstName || '').localeCompare(b.firstName || '');

          if (nameComparison !== 0) {
            return nameComparison;
          } else {
            return (a.lastName || '').localeCompare(b.lastName || '');
          }
        }
      });

      let tableRows = ''; // Initialize an empty string to store table rows
      let noOfThaali = 0;
      let prevColor = ''; // Variable to store the previous color

      // Loop through each sector in overrideDetails and create table rows
      Object.keys(overrideDetails).forEach((key, index) => {
        const sector = overrideDetails[key];
        // Check the value of sector.count
        const count = parseInt(sector.count); // Convert count to an integer
        if (count === 0) {
          // If count is 0, don't print the label
          return; // Skip this iteration
        } else {
          // Determine the color based on packageType
          let color = PackageTypeColor[sector.packageType];

          // Check if the current color is different from the previous color
          if (color !== prevColor) {
            noOfThaali = 1; // Reset noOfThaali to 1
            prevColor = color; // Update the previous color
          } else {
            noOfThaali += 1; // Increment noOfThaali
          }

          const currentUrl = window.location.origin;

          // If count is 1 or greater, print the label based on the count value
          for (let i = 0; i < count; i++) {
            const qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=${encodeURIComponent(`${currentUrl}/qrcode/${sector.orgId}`)}`;

            // Create a table row with table cells for each detail
            tableRows += `
                <div class="print-div">
                  <table border="1" cellpadding="0" cellspacing="0">
                    <tbody>
                      <tr>
                        <td COLSPAN=2 align="center">${sector.orgId}</td>
                        <td  align="center">
                        <img style="margin: 0;;padding: 0;width: 100px;height: 100px;background-color: hsl(0, 0%, 90%);transition: background-color 300ms;" src=${qrCodeUrl}></td>

                      </tr>
                      <tr>
                        <td COLSPAN=3 align="center">${sector.firstName} ${sector.lastName}</td>
                      </tr>
                      <tr>
                        <td COLSPAN=2 align="center" style="font-size: 15px;">${sector.sector}</td>
                        <td align="center" style="font-size: 15px;">${color} <span style="font-size: 10px; ">
                          ${noOfThaali}
                        </span></td>
                        </tr>
                    </tbody>
                  </table>
                </div>`;
          }
        }
      });

      // Create the complete HTML table with headers and rows
      const table = `
                    ${tableRows} <!-- Insert generated table rows -->
      
        `;
      return table; // Return the complete HTML table
    };

    const printTables = (tablesHTML) => {
      const printWindow = window.open('', '_blank');
      if (printWindow) {
        printWindow.document.open();
        printWindow.document.write(`
            <html>
              <head>
                <title>A4 Page Using CSS</title>
                <style>
                  @media print {
                    body, html, p {
                      margin: 0pt !important;
                      padding: 0pt !important;
                    }
                    @page {
                      margin: 0pt !important;
                      padding: 0pt !important;
                    }
                    .print-div {
                      width: 114mm;
                      height: 81mm;
                      page-break-after: always;
                      margin: 0;
                      padding: 10px;
                    }
                    .print-div table {
                      width: 100%;
                      height: 100%;
                      border-collapse: collapse;
                    }
                    .print-div table td {
                      border: 1px solid black;
                      text-align: center;
                    }
                    .print-div table td:nth-child(1) {
                      font-size: 47px;
                      font-weight: bold;
                    }
                    .print-div table td:nth-child(2) {
                      font-size: 40px;
                      font-weight: bold;
                    }
                    .print-div table td:nth-child(3),
                    .print-div table td:nth-child(4) {
                      font-size: 20px;
                    }
                  }
                </style>
              </head>
              <body>
                ${tablesHTML}
              </body>
            </html>
          `);
        // printWindow.document.close();
        setTimeout(() => {
          printWindow.focus();
          printWindow.print();
        }, 1000); // Delay execution for 1 second (adjust the delay as needed)
        // Wait for the content to load before printing
        // printWindow.close(); // Close the window after printing
      } else {
        console.log('Could not open new window for printing');
      }

      // Wait for the window to load before handling the print dialog

    };


    // Call the function with the combined HTML code for all tables

    const handlePrint = overrideDetails => {
      printTables(generatePrintableLabels(overrideDetails));
    };

    const buildHeader = () => {
      return (
        <TableHead>
          <TableRow>
            <StyledTableCell>
              <strong>Sector</strong>
            </StyledTableCell>
            {Object.keys(PackageColor).map((key: any, index: any) => {
              return (
                <StyledTableCell key={'package-color-' + key}>
                  <strong>{key}</strong>
                  <div>
                    <span>({PackageColorType[key]})</span>
                  </div>
                </StyledTableCell>
              );
            })}
            <StyledTableCell align="right">
              <strong>Total</strong>
            </StyledTableCell>
          </TableRow>
        </TableHead>
      );
    };
    // Define a function to handle merging overrideDetails
    const handleMergeOverrideDetails = (type) => {
      let allOverrideDetails = {};
      const sortedSectorKeys = Object.keys(sectorCounts).sort();

      // Iterate over the sorted sector keys
      sortedSectorKeys.forEach((sectorKey) => {
        allOverrideDetails[sectorKey] = this.props.reportDailyThaliCount.sectorCounts[sectorKey].overrideDetails;
      });

      // Do something with allOverrideDetails, such as logging or further processing
      console.log(allOverrideDetails);

      // Define a variable to store the combined table
      let combinedHTML = '';


      // Extract the appropriate entries based on the provided type
      let selectedEntries;
      switch (type) {
        case "All":
          // Iterate over each sector in allOverrideDetails

          Object.values(allOverrideDetails).forEach((sectorOverrideDetails) => {
            // Asserting the type of sectorOverrideDetails to an array of objects with the expected structure
            const detailsArray = sectorOverrideDetails as Array<{ count: number, firstName: string, lastName: string, mealCountOverrideType: string, noRice: boolean, orgId: string, packageType: string, sector: string, subscriberId: string }>;

            // Extract the packageType "Medium" from each entry inside the sectorOverrideDetails array
            const mediumPackages = detailsArray
              .filter(entry => entry.packageType === "Single");

            // Generate the print labels table for the current sector
            const sectorTable = generatePrintableLabels(mediumPackages);
            // Append the generated table to the combined table
            combinedHTML += sectorTable;
          });
          Object.values(allOverrideDetails).forEach((sectorOverrideDetails) => {
            // Asserting the type of sectorOverrideDetails to an array of objects with the expected structure
            const detailsArray = sectorOverrideDetails as Array<{ count: number, firstName: string, lastName: string, mealCountOverrideType: string, noRice: boolean, orgId: string, packageType: string, sector: string, subscriberId: string }>;

            // Extract the packageType "Medium" from each entry inside the sectorOverrideDetails array
            const mediumPackages = detailsArray
              .filter(entry => entry.packageType === "Medium");

            // Generate the print labels table for the current sector
            const sectorTable = generatePrintableLabels(mediumPackages);
            // Append the generated table to the combined table
            combinedHTML += sectorTable;
          });
          Object.values(allOverrideDetails).forEach((sectorOverrideDetails) => {
            // Asserting the type of sectorOverrideDetails to an array of objects with the expected structure
            const detailsArray = sectorOverrideDetails as Array<{ count: number, firstName: string, lastName: string, mealCountOverrideType: string, noRice: boolean, orgId: string, packageType: string, sector: string, subscriberId: string }>;

            // Extract the packageType "Medium" from each entry inside the sectorOverrideDetails array
            const mediumPackages = detailsArray
              .filter(entry => entry.packageType === "Regular");

            // Generate the print labels table for the current sector
            const sectorTable = generatePrintableLabels(mediumPackages);
            // Append the generated table to the combined table
            combinedHTML += sectorTable;
          });

          printTables(combinedHTML);

          break;
        case "Blue":
          // Iterate over each sector in allOverrideDetails
          Object.values(allOverrideDetails).forEach((sectorOverrideDetails) => {
            // Asserting the type of sectorOverrideDetails to an array of objects with the expected structure
            const detailsArray = sectorOverrideDetails as Array<{ count: number, firstName: string, lastName: string, mealCountOverrideType: string, noRice: boolean, orgId: string, packageType: string, sector: string, subscriberId: string }>;

            // Extract the packageType "Medium" from each entry inside the sectorOverrideDetails array
            const mediumPackages = detailsArray
              .filter(entry => entry.packageType === "Single");

            // Generate the print labels table for the current sector
            const sectorTable = generatePrintableLabels(mediumPackages);
            // Append the generated table to the combined table
            combinedHTML += sectorTable;
          });

          // Now combinedTable contains all the tables generated for each sector
          printTables(combinedHTML);
          break;
        case "White":
          // Iterate over each sector in allOverrideDetails
          Object.values(allOverrideDetails).forEach((sectorOverrideDetails) => {
            // Asserting the type of sectorOverrideDetails to an array of objects with the expected structure
            const detailsArray = sectorOverrideDetails as Array<{ count: number, firstName: string, lastName: string, mealCountOverrideType: string, noRice: boolean, orgId: string, packageType: string, sector: string, subscriberId: string }>;

            // Extract the packageType "Medium" from each entry inside the sectorOverrideDetails array
            const mediumPackages = detailsArray
              .filter(entry => entry.packageType === "Medium");

            // Generate the print labels table for the current sector
            const sectorTable = generatePrintableLabels(mediumPackages);
            // Append the generated table to the combined table
            combinedHTML += sectorTable;
          });

          // Now combinedTable contains all the tables generated for each sector
          printTables(combinedHTML);
          break;
        case "Grey":
          // Iterate over each sector in allOverrideDetails
          Object.values(allOverrideDetails).forEach((sectorOverrideDetails) => {
            // Asserting the type of sectorOverrideDetails to an array of objects with the expected structure
            const detailsArray = sectorOverrideDetails as Array<{ count: number, firstName: string, lastName: string, mealCountOverrideType: string, noRice: boolean, orgId: string, packageType: string, sector: string, subscriberId: string }>;

            // Extract the packageType "Medium" from each entry inside the sectorOverrideDetails array
            const mediumPackages = detailsArray
              .filter(entry => entry.packageType === "Regular");

            // Generate the print labels table for the current sector
            const sectorTable = generatePrintableLabels(mediumPackages);
            // Append the generated table to the combined table
            combinedHTML += sectorTable;
          });

          // Now combinedTable contains all the tables generated for each sector
          printTables(combinedHTML);
          break;
        // Add more cases for other types if needed
        default:
          // Handle invalid type
          console.error("Invalid type:", type);
          return;
      }

    };


    return (
      <div className="daily-meal-count-report-container">
        <h5>Print Label Sector Wise</h5>
        <Divider />
        <div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              name={'selectedDate'}
              margin="normal"
              value={this.state ? this.state.selectedDate : new Date()}
              onChange={value => {
                const selectedDate = dateFns.format(value, 'yyyy-MM-dd', {
                  awareOfUnicodeTokens: true
                });
                const scheduleForSelectedDate = this.getScheduleForSelectedDate(
                  selectedDate
                );
                this.setState({
                  selectedDate: value,
                  noMeal:
                    scheduleForSelectedDate && scheduleForSelectedDate.noMeal
                });
                if (scheduleForSelectedDate) {
                  !scheduleForSelectedDate.noMeal &&
                    this.props.getMealCountBySectorForSelectedDateV2(
                      selectedDate
                    );
                } else {
                  this.props.getMealCountBySectorForSelectedDateV2(
                    selectedDate
                  );
                }
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
        <Divider />
        <div>
          <Spinner active={this.props.isBusyCommunicating}>
            <React.Fragment>
              {this.props.reportDailyThaliCount && (
                <Paper>
                  {Object.keys(sectorCounts).length == 0 ||
                    (this.state && this.state.noMeal) ? (
                    <div className="daily-meal-count-no-report-container">
                      <h6> Report Not Available!!</h6>
                    </div>
                  ) : (


                    <div className="daily-meal-count-report-table-container">
                      <Table className="daily-meal-count-report-table" >
                        {buildHeader()}
                        <TableBody>
                          {Object.keys(sectorCounts)
                            .sort() // Sort the keys alphabetically
                            .map((sectorKey, sectorIndex) => {
                              let sectorCount = 0;
                              return (
                                <React.Fragment
                                  key={'sector-container-' + sectorIndex}
                                >
                                  <StyledTableRow>
                                    {buildSectorNameCell(sectorKey)}
                                    {Object.values(PackageColorType).map(
                                      (
                                        packageTypeTiffinCountKey: any,
                                        packageTypeTiffinCountIndex: any
                                      ) => {
                                        const sectorPackageCount = calculateSectorPackageCount(
                                          sectorKey,
                                          packageTypeTiffinCountKey
                                        );
                                        sectorCount = calculateTotalCount(
                                          sectorPackageCount,
                                          sectorCount,
                                          packageTypeTiffinCountKey
                                        );
                                        return (
                                          <React.Fragment
                                            key={
                                              packageTypeTiffinCountKey +
                                              '-' +
                                              packageTypeTiffinCountIndex
                                            }
                                          >
                                            <StyledTableCell>
                                              {sectorPackageCount
                                                ? sectorPackageCount
                                                : 0}
                                            </StyledTableCell>
                                            {Object.keys(PackageType).length -
                                              1 ===
                                              packageTypeTiffinCountIndex && (
                                                <StyledTableCell>
                                                  <div className="daily-meal-count-report-expand-more">
                                                    <span>{sectorCount}</span>
                                                    <StyledIconButton
                                                      onClick={() =>
                                                        handlePrint(
                                                          this.props
                                                            .reportDailyThaliCount
                                                            .sectorCounts[sectorKey]
                                                            .overrideDetails
                                                        )
                                                      }
                                                      aria-label="Show more"
                                                    >
                                                      <PrintIcon />
                                                    </StyledIconButton>
                                                  </div>
                                                </StyledTableCell>
                                              )}
                                          </React.Fragment>
                                        );
                                      }
                                    )}
                                  </StyledTableRow>
                                </React.Fragment>
                              );
                            }
                            )}
                        </TableBody>
                        <TableFooter>
                          <StyledTableRow>
                            <StyledTableCell>
                              <strong> Total Thali Count : </strong>
                            </StyledTableCell>
                            {Object.keys(PackageType).map(
                              (packageTypeTiffinCountKey: any) => {
                                if (
                                  !isNaN(
                                    totalCountPacakageType[
                                    packageTypeTiffinCountKey
                                    ]
                                  )
                                ) {
                                  totalCount =
                                    totalCount +
                                    totalCountPacakageType[
                                    packageTypeTiffinCountKey
                                    ];
                                }
                                return (
                                  <React.Fragment
                                    key={
                                      'total-count-' + packageTypeTiffinCountKey
                                    }
                                  >
                                    <StyledTableCell>
                                      <strong>
                                        {
                                          totalCountPacakageType[
                                          packageTypeTiffinCountKey
                                          ]
                                        }
                                      </strong>
                                    </StyledTableCell>
                                  </React.Fragment>
                                );
                              }
                            )}
                            <StyledTableCell>
                              <strong>{totalCount}</strong>
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableFooter>
                      </Table>
                    </div>)}
                  <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "16px" }}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => handleMergeOverrideDetails("All")}
                      disabled={Object.keys(sectorCounts).length === 0 || (this.state && this.state.noMeal)}
                    >
                      Print All Sectors
                    </Button>

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => handleMergeOverrideDetails("Blue")}
                      disabled={Object.keys(sectorCounts).length === 0 || (this.state && this.state.noMeal)}
                    >
                      Print Blue Labels
                    </Button>

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => handleMergeOverrideDetails("White")}
                      disabled={Object.keys(sectorCounts).length === 0 || (this.state && this.state.noMeal)}
                    >
                      Print White Labels
                    </Button>

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => handleMergeOverrideDetails("Grey")}
                      disabled={Object.keys(sectorCounts).length === 0 || (this.state && this.state.noMeal)}
                    >
                      Print Grey Labels
                    </Button>
                  </div>


                </Paper>
              )}
            </React.Fragment>
          </Spinner>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    reportDailyThaliCount:
      state.operations && state.operations.reportDailyThaliCount,
    isBusyCommunicating: state.isBusyCommunicating,
    schedules: state.schedules
  };
};

export default requireAuth(
  connect(mapStateToProps, { ...adminReportsAction, ...scheduleAction })(
    PrintLabelSectorWise
  )
);
