import axios from 'axios';
import {
    UPDATE_SECTOR,
    AUTH_ERROR
} from './actionType';
import { TOKEN_API_ENPOINT, UPDATE_SECTOR_ENDPOINT } from '../api/API';
import { Sector } from '../type/Type';

export const updateSector = (
    sector: Sector,
    workFlowProcessor: Function,
    onErrorCallback: Function
) => async (dispatch: Function) => {
    try {
        const response = await axios.post(UPDATE_SECTOR_ENDPOINT(), sector);
        if (response && response.status === 200) {
            dispatch({ type: UPDATE_SECTOR, payload: sector });
            workFlowProcessor && workFlowProcessor();
        }
    } catch (e) {
        onErrorCallback && onErrorCallback();
        dispatch({ type: AUTH_ERROR, payload: 'Cannot Update Zone' });
    }
};
