import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { QR_CODE_ENDPOINT } from '../../api/API';

interface QrCodeRedirectProps {
    orgId: string;
}

const QrCodeRedirect: React.FC<QrCodeRedirectProps> = ({ orgId }) => {
    const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchRedirectUrl = async () => {
            try {
                const response = await axios.get<string>(QR_CODE_ENDPOINT(orgId));
                if (response.data) {
                    const encodedUrl = encodeURIComponent(response.data);
                    setRedirectUrl(encodedUrl); // Set the encoded URL directly
                } else {
                    setError('QR code URL not found or invalid in response');
                }
            } catch (error) {
                setError('Error fetching redirect URL: ' + (error.message || 'Unknown error'));
            }
        };

        fetchRedirectUrl();
    }, [orgId]);

    useEffect(() => {
        if (redirectUrl) {
            window.open(decodeURIComponent(redirectUrl), '_blank'); // Open decoded URL in new tab
        }
    }, [redirectUrl]);

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div>
            <h2>QR Code Redirect</h2>
            <p>Redirecting to QR code URL...</p>
        </div>
    );
};

export default QrCodeRedirect;
