import axios from 'axios';
import {
    UPDATE_THALISIZE,
    AUTH_ERROR
} from './actionType';
import { TOKEN_API_ENPOINT, UPDATE_THALISIZE_ENDPOINT } from '../api/API';
import { ThaliSize } from '../type/Type';

export const updateThaliSize = (
    thalisize: ThaliSize,
    workFlowProcessor: Function,
    onErrorCallback: Function
) => async (dispatch: Function) => {
    try {
        const response = await axios.post(UPDATE_THALISIZE_ENDPOINT(), thalisize);
        if (response && response.status === 200) {
            dispatch({ type: UPDATE_THALISIZE, payload: thalisize });
            workFlowProcessor && workFlowProcessor();
        }
    } catch (e) {
        onErrorCallback && onErrorCallback();
        dispatch({ type: AUTH_ERROR, payload: 'Cannot Update Thaali Size' });
    }
};
