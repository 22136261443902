import React from 'react';
import requireAuth from '../../requireAuth';
import { Grid, Card, CardHeader, Avatar, CardContent } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import './Dashboard.css';


const AdminDashboard = props => {
  return (
    <div>
      <div className="Dashboard-container">
        <div className="Dashboard-card-container">
          <Card className="Card-container">
            <CardContent>
              <h6>Admin Dashboard </h6>
            </CardContent>
          </Card>
        </div>

        <div className="Dashboard-card-container">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <Card className="Card-container">
                <CardHeader
                  avatar={
                    <Avatar>
                      <PersonIcon />
                    </Avatar>
                  }
                  title="TOTAL USERS: 572"
                />
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card className="Card-container">
                <CardHeader
                  avatar={
                    <Avatar>
                      <PersonIcon />
                    </Avatar>
                  }
                  title="REGISTERED USERS: 572"
                />
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card className="Card-container">
                <CardHeader
                  avatar={
                    <Avatar>
                      <PersonIcon />
                    </Avatar>
                  }
                  title="ACTIVE USERS: 572"
                />
              </Card>
            </Grid>
          </Grid>
        </div>

      </div>
    </div>
  );
};

export default requireAuth(AdminDashboard);
