import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import Navigator from '../Navigator/Navigator';
import { connect } from 'react-redux';
import Header from '../Header/Header';
import Login from '../Login/Login';
import Dashboard from '../Dashboard/Dashboard';
import OperationDashboard from '../Dashboard/OperationDashboard';
import CRMDashboard from '../Dashboard/CRMDashboard';
import MealSchedule from '../MealSchedule/MealSchedule';
import UserForm from '../User/UserForm';
import MenuSchedule from '../Menu/Subscriber/MenuSchedule';
import MenuDetails from '../Menu/Subscriber/MenuDetails';
import DailyMealCountReport from '../Operations/Reports/DailyMealCountReport';
import DailyMealCountReportV2 from '../Operations/Reports/DailyMealCountReport_V2';

import HelpContact from '../Contact/HelpContact';

import GlobalErrorHandler from '../../components/ErrorHandler/GlobalErrorHandler';

import { AppState } from '../../type/Type';

import './AppRouter.css';
import ReviewDetails from '../Menu/Subscriber/ReviewDetails';
import AddTiffin from '../Operations/CRM/AddTiffin';
import PrintLabelsSectorWise from '../Operations/Labels/PrintLabelsSectorWise';
import AdminDashboard from '../Dashboard/AdminDashboard';
import RegisterDetails from '../Menu/Subscriber/RegisterDetails';
import { RootState } from '../../store/store';
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute'; // Adjust import as per your file structure
import QrCodeRedirect from '../QrCode/QrCodeRedirect'; // Adjust import as per your file structure

class AppRouter extends Component<any, any> {
  render() {
    return (
      <div className="App-container">
        <Router>
          <div>
            <Header />
            <GlobalErrorHandler />
            <Switch>
              {/* Public Routes */}
              <Route path="/login" component={Login} />
              <Route path="/help-contact/" component={HelpContact} />
              <Route path="/logout" component={Login} />

              {/* Protected Routes */}
              <ProtectedRoute exact path="/" component={Dashboard} role="FMB_ROLE_SUBSCRIBER" />
              <ProtectedRoute exact path="/dashboard/" component={Dashboard} role="FMB_ROLE_SUBSCRIBER" />
              <ProtectedRoute exact path="/meal-schedule/" component={MealSchedule} role="FMB_ROLE_SUBSCRIBER" />
              <ProtectedRoute exact path="/menu-schedule/" component={MenuSchedule} role="FMB_ROLE_SUBSCRIBER" />
              <ProtectedRoute exact path="/menu-schedule/details/:currentDate" component={MenuDetails} role="FMB_ROLE_SUBSCRIBER" />
              <ProtectedRoute exact path="/menu-schedule/review/:currentDate" component={ReviewDetails} role="FMB_ROLE_SUBSCRIBER" />
              <ProtectedRoute exact path="/menu-schedule/register/:currentDate" component={RegisterDetails} role="FMB_ROLE_SUBSCRIBER" />
              <ProtectedRoute exact path="/profile/" component={UserForm} role="FMB_ROLE_SUBSCRIBER" />
              <ProtectedRoute exact path="/operation/dashboard/" component={OperationDashboard} role="FMB_ROLE_SUBSCRIBER" />
              <ProtectedRoute exact path="/operation/crm-dashboard/" component={CRMDashboard} role="FMB_ROLE_CRM_OPERATION" />
              <ProtectedRoute exact path="/operation/admin-dashboard/" component={AdminDashboard} role="FMB_ROLE_SUPER" />
              <ProtectedRoute exact path="/operation/crm/add-tiffin" component={AddTiffin} role="FMB_ROLE_CRM_OPERATION" />
              <ProtectedRoute exact path="/operation/meal-count-report/" component={DailyMealCountReport} role="FMB_ROLE_OPERATIONS" />
              <ProtectedRoute exact path="/operation/meal-count-report/v2/" component={DailyMealCountReportV2} role="FMB_ROLE_SUBSCRIBER" />
              <ProtectedRoute exact path="/operation/label-print/" component={PrintLabelsSectorWise} role="FMB_ROLE_PL_OPERATION" />
              <ProtectedRoute exact path="/qrcode/:orgId" component={QrCodeRedirectWrapper} role="FMB_ROLE_DANA" />

              {/* Default Redirect */}
              <Redirect to="/login" />
            </Switch>
            <Navigator />
          </div>
        </Router>
      </div>
    );
  }
}

const QrCodeRedirectWrapper = ({ match }) => {
  const { orgId } = match.params;
  return <QrCodeRedirect orgId={orgId} />;
};


const mapStateToProps = (state: RootState) => ({
  auth: state.authentication.authenticated,
  roles: state.authentication.decodedToken.roles
});

export default connect(mapStateToProps)(AppRouter);
