export const LOCAL_STORAGE_TOKEN = 'aefFMBToken';

export const USER_ERROR = 'USER_ERROR';
export const SERVER_ERROR = 'SERVER_ERROR';

export const WEEKDAYS  = {
   0 : "SUNDAY",
   1 : "MONDAY",
   2 : "TUESDAY",
   3 : "WEDNESDAY",
   4 : "THURSDAY",
   5 :  "FRIDAY",
   6 : "SATURDAY",
   
}