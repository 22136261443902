// src/components/ProtectedRoute/ProtectedRoute.tsx
import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
    doesUserBelongsToOperation,
    doesUserBelongsToCRMOperation,
    doesUserBelongsToPrintLabelOperation,
    doesUserBelongsToDana,
    isUserSubscriber,
    isSuper
} from '../../util/authorization';
import { RootState } from '../../store/store'; // Adjust the import path as needed

interface ProtectedRouteProps extends RouteProps {
    component: React.ComponentType<any>;
    role: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component: Component, role, ...rest }) => {
    const { authenticated, decodedToken } = useSelector((state: RootState) => state.authentication);

    const userHasRequiredRole = (): boolean => {
        if (!decodedToken || !decodedToken.roles) return false;

        switch (role) {
            case 'FMB_ROLE_OPERATIONS':
                return doesUserBelongsToOperation(decodedToken.roles);
            case 'FMB_ROLE_CRM_OPERATION':
                return doesUserBelongsToCRMOperation(decodedToken.roles);
            case 'FMB_ROLE_PL_OPERATION':
                return doesUserBelongsToPrintLabelOperation(decodedToken.roles);
            case 'FMB_ROLE_SUBSCRIBER':
                return isUserSubscriber(decodedToken.roles);
            case 'FMB_ROLE_SUPER':
                return isSuper(decodedToken.roles);
            case 'FMB_ROLE_DANA':
                return doesUserBelongsToDana(decodedToken.roles);
            default:
                return false;
        }
    };

    return (
        <Route
            {...rest}
            render={(props) =>
                authenticated && userHasRequiredRole() ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
    );
};

export default ProtectedRoute;
