import React, { Component } from 'react';
import requireAuth from '../../requireAuth';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded';
import RestaurantMenuRoundedIcon from '@material-ui/icons/RestaurantMenuRounded';
import CreateRoundedIcon from '@material-ui/icons/CreateRounded';
import * as scheduleAction from '../../reducers/scheduleAction';
import * as profileAction from '../../reducers/profileAction';
import PersonOutlineIcon from '@material-ui/icons/PersonOutlineTwoTone';

import Spinner from '../Spinner/Spinner';

import Divider from '@material-ui/core/Divider/Divider';
import {
  AppState,
  Schedule,
  Contributor,
  MenuItem,
  LabelValue,
  PackageTypeColor,
  ContributionType_TIFFIN,
  ContributionType_FATEHA
} from '../../type/Type';
import { Link } from 'react-router-dom';

import './Dashboard.css';
import Grid from '@material-ui/core/Grid';
const dateFns = require('date-fns');

// Exporting the buildMenuItem function
export const buildMenuItem = (menuItems: MenuItem[] | null, noMealReason: string) => {
  return (
    menuItems &&
    menuItems.map((menuItem: MenuItem, index: number) => {
      return (
        <span className="Dashboard-menu-items-each" key={index}>
          {menuItem.itemName}<br />
        </span>
      );
    })
  );
};
interface DashBoardState {
  todaysSchedule: Schedule | null;
  formattedTodaysDate: string | null;
  tiffinContributor: Contributor | null;
  fatehaContributor: Contributor | null;
}

class Dashboard extends Component<any, DashBoardState> {
  constructor(props: any) {
    super(props);
    this.state = {
      todaysSchedule: null,
      formattedTodaysDate: null,
      tiffinContributor: null,
      fatehaContributor: null
    };
    this.props.getUserProfile(this.props.subscriberId);

  }

  componentDidMount() {
    this.props.getMonthsSchedule();
    this.setState({
      formattedTodaysDate: dateFns.format(new Date(), 'dd-MMM-yyyy', {
        awareOfUnicodeTokens: true
      })
    });
  }

  componentDidUpdate(prevProps: any, prevState: DashBoardState) {
    if (
      this.props.schedule !== prevProps.schedule ||
      (this.state && this.state.todaysSchedule) !==
      (prevState && prevState.todaysSchedule) ||
      this.props.isBusyCommunicating !== prevProps.isBusyCommunicating
    ) {
      this.setState({
        todaysSchedule: this.props.schedule,
        tiffinContributor:
          this.props.schedule &&
          this.props.schedule.contributors &&
          this.props.schedule.contributors.filter(
            contributor =>
              contributor.contributionType === ContributionType_TIFFIN
          )[0],
        fatehaContributor:
          this.props.schedule &&
          this.props.schedule.contributors &&
          this.props.schedule.contributors.filter(
            contributor =>
              contributor.contributionType === ContributionType_FATEHA
          )[0]
      });
    }
  }

  render() {
    const MealScheduleLink = (props: any) => (
      <Link to="/meal-schedule" {...props} />
    );
    const MenuScheduleLink = (props: any) => (
      <Link to="/menu-schedule" {...props} />
    );
    // const buildMenuItem = (
    //   menuItems: MenuItem[] | null,
    //   noMealReason: string
    // ) => {
    //   return (
    //     menuItems &&
    //     menuItems.map((menuItem: MenuItem, index: number) => {
    //       return (
    //         <span className="Dashboard-menu-items-each" key={index}>
    //           {' '}
    //           {/* &#x2726;  */}
    //           {menuItem.itemName}<br />
    //         </span>
    //       );
    //     })
    //   );
    // };
    const buildMessages = (messages: LabelValue[] | null) => {
      return (
        messages &&
        messages.map((message: LabelValue, index: number) => {
          return (
            <div className="Dashboard-menu-side-note Dashboard-menu-container_items">
              <div className="Dashboard-menu-side-note_header">

                <strong> Thaali Khidmat</strong>

              </div>
              <div className="Dashboard-menu-side-note_content">
                {message && message.messageValue}
              </div>
            </div>
          );
        })
      );
    };
    return (
      <div>
        <div className="icon-text-container">

        </div>

        <Spinner active={this.props.isBusyCommunicating}>
          {this.state && (
            <React.Fragment>
              <div className="Dashboard-container">

                <div className="Dashboard-card-container">
                  <Card className="Card-container">
                    <CardContent>
                      {/* <img className="rounded-full" src="https://aefumoordakhiliyah.blob.core.windows.net/photo-files/347da16b02d94f4bb071ca4cec507c7f.jpeg" alt="" style={{ width: '35px', height: '35px', minWidth: '35px', minHeight: '35px' }} /> */}
                      <PersonOutlineIcon />
                      <span className="icon-text">{this.props.profile.firstName} {this.props.profile.lastName} ({this.props.subscriberId})</span>
                      <hr /> {/* Horizontal line */}
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography >
                            <b>Sector:</b> {this.props.profile.zone}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography >
                            <b>Thaali Size:</b> {PackageTypeColor[this.props.profile.thaliSize]}
                          </Typography>
                        </Grid>
                      </Grid>                      {/* <Typography color="textSecondary">
                        Thali Pick up Address
                      </Typography>
                      <Typography component="p">
                        <b>6635 Campobello Rd, Mississauga, ON L5N 2W9</b>
                        <br />
                      </Typography> */}
                    </CardContent>
                    {/* <CardActions>
                      <Button size="small">Learn More</Button>
                    </CardActions> */}
                  </Card>
                </div>



                <div className="Dashboard-card-container">
                  <Card className="Card-container">
                    <CardContent>
                      <div className="Dashboard-date-display-container">
                        <CalendarTodayRoundedIcon fontSize="small" />
                        <span className="Dashboard-date-display">
                          <strong>
                            {dateFns.format(new Date(), 'dd-MMM-yyyy', {
                              awareOfUnicodeTokens: true
                            })}
                          </strong>
                        </span>
                      </div>
                      {!this.state.todaysSchedule && (
                        <React.Fragment>
                          <Typography variant="h5">
                            <span className="Dashboard-no-details">
                              No Thali Today{' '}
                            </span>
                          </Typography>
                        </React.Fragment>
                      )}
                      {this.state.todaysSchedule && (
                        <React.Fragment>
                          {this.state.todaysSchedule.noMeal && (
                            <div className="Dashboard-nomeal-contianer">
                              <Typography component="p">
                                <strong>
                                  {' '}
                                  No Thali :{' '}
                                  {this.state.todaysSchedule.noMealReason}{' '}
                                </strong>
                              </Typography>
                            </div>
                          )}
                          {this.state.tiffinContributor && (
                            <div className="Dashboard-contributor-contianer">
                              <Typography component="div">
                                Aaj ni thaali ni khidmat
                              </Typography>
                              <Typography component="div">
                                <span>
                                  <strong>
                                    {`${this.state.tiffinContributor &&
                                      this.state.tiffinContributor.user &&
                                      this.state.tiffinContributor.user
                                        .firstName}     
                                      ${this.state.tiffinContributor &&
                                      this.state.tiffinContributor.user &&
                                      this.state.tiffinContributor.user
                                        .lastName}  
                                      & family`}
                                  </strong>
                                </span>
                              </Typography>
                            </div>
                          )}
                          <div className="Dashboard-menu-side-note Dashboard-menu-container_items">
                            <div className="Dashboard-menu-side-note_header">
                              <strong>Menu
                              </strong>
                            </div>
                            <div className="Dashboard-menu-side-note_content">
                              {buildMenuItem(
                                this.state.todaysSchedule.menu &&
                                this.state.todaysSchedule.menu
                                  .items,
                                this.state.todaysSchedule.noMealReason
                              )}
                            </div>
                          </div>


                          {this.state.todaysSchedule &&
                            this.state.todaysSchedule
                              .instructionsForSubscriber &&
                            this.state.todaysSchedule.instructionsForSubscriber
                              .length > 0 &&
                            this.state.todaysSchedule
                              .instructionsForSubscriber[0].messageValue
                              .length > 0 && (
                              <React.Fragment>
                                <div className="Dashboard-instructionForSubscriber-container">
                                  {buildMessages(
                                    this.state.todaysSchedule
                                      .instructionsForSubscriber
                                  )}
                                </div>
                              </React.Fragment>
                            )}

                          {this.state.fatehaContributor &&
                            this.state.fatehaContributor
                              .messageFromContributor && (
                              <div className="Dashboard-menu-side-note Dashboard-menu-container_items">
                                <div className="Dashboard-menu-side-note_header">
                                  <strong>
                                    {
                                      this.state.fatehaContributor.messageFromContributor.filter(
                                        messageLabel =>
                                          messageLabel &&
                                          messageLabel.messageLabel.toLowerCase() ===
                                          ContributionType_FATEHA.toLowerCase()
                                      )[0].messageLabel
                                    }
                                  </strong>
                                </div>
                                <div className="Dashboard-menu-side-note_content">
                                  {
                                    this.state.fatehaContributor.messageFromContributor.filter(
                                      messageLabel =>
                                        messageLabel &&
                                        messageLabel.messageLabel.toLowerCase() ===
                                        ContributionType_FATEHA.toLowerCase()
                                    )[0].messageValue
                                  }
                                </div>
                                <br />
                                <Divider />
                              </div>
                            )}



                          <div className="Dashboard-menu-side-note Dashboard-menu-container_items">
                            <div className="Dashboard-menu-side-note_header">
                              <strong>Caterer
                              </strong>
                            </div>
                            <div className="Dashboard-menu-side-note_content">
                              {this.state.todaysSchedule.caterer}
                            </div>
                          </div>


                        </React.Fragment>
                      )}
                    </CardContent>


                    {/* <CardActions>
                    <Button size="small">Learn More</Button>
                  </CardActions> */}
                  </Card>
                </div>
                {/*<div className="Dashboard-card-container">
                  <Card className="Card-container">
                    <CardContent>
                      <Typography color="textSecondary">
                        Sector : Jamali
                      </Typography>
                       <Typography  color="textSecondary">
                      Thali Pick up Address
                    </Typography>
                    <Typography component="p">
                      155 Argentia Rd, Mississauga, L1L2L3
                      <br />
                    </Typography> 
                    </CardContent>
                     <CardActions>
                    <Button size="small">Learn More</Button>
                  </CardActions> 
                  </Card>
                </div>*/}
                <div className="Dashboard-button-row-container">
                  <div className="Dashboard-button-row">
                    <div className="Dashboard-button-container">
                      <Button
                        component={MenuScheduleLink}
                        variant="contained"
                        color="secondary"
                        className="Dashboard-button"
                        style={{ backgroundColor: '#f40158' }}

                      >
                        <span className="Dashboard-button-content-container">
                          Menu
                          <RestaurantMenuRoundedIcon />
                        </span>
                      </Button>
                    </div>
                    <div className="Dashboard-button-container">
                      <Button
                        component={MealScheduleLink}
                        variant="contained"
                        color="secondary"
                        className="Dashboard-button"
                        style={{ backgroundColor: '#f40158' }}
                        disabled={!this.props.profile.fmbregistered}

                      >
                        <span className="Dashboard-button-content-container">
                          <p>Going on Vacation</p>
                          <CreateRoundedIcon />
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
            </React.Fragment>
          )}
        </Spinner>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    subscriberId: state.authentication.decodedToken.subscriberId,
    profile: state.profile,
    schedule: state.schedules.find(
      schedule =>
        schedule.dailyDate ===
        dateFns.format(new Date(), 'yyyy-MM-dd', { awareOfUnicodeTokens: true })
    ) as Schedule,
    isBusyCommunicating: state.isBusyCommunicating
  };
};

// export default requireAuth(connect(mapStateToProps, scheduleAction)(Dashboard));

// Connect your reducers and Dashboard component
const ConnectedDashboard = connect(
  mapStateToProps,
  { ...scheduleAction, ...profileAction } // Merge both action creators
)(Dashboard);

// Export your connected Dashboard wrapped with requireAuth
export default requireAuth(ConnectedDashboard);