import 'date-fns';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import requireAuth from '../../../requireAuth';
import { withStyles } from '@material-ui/core/styles';
import {
  Schedule,
  AppState,
  MenuItem as FoodMenuItems,
  OverrideSchedule,
  LabelValue,
  SubscriptionSchedule
} from '../../../type/Type';
import * as scheduleAction from '../../../reducers/scheduleAction';
import * as mealscheduleAction from '../../../reducers/mealscheduleAction';
import * as sectorAction from '../../../reducers/sectorAction';
import * as profileAction from '../../../reducers/profileAction';
import * as thaliSizeAction from '../../../reducers/thaliSizeAction';
import * as contentAction from '../../../reducers/contentAction'; // Import the content action creator

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import './MenuDetails.css';
import { WEEKDAYS } from '../../../util/constant';
import Spinner from '../../Spinner/Spinner';
import { isMealCancellationEnabled } from '../menuSchedulingUtils';
import { MenuItem, Select } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dateFns = require('date-fns');

interface RegisterDetailsState {
  numberOfOptedMealForSelectedDay: number;
  cancelOrReduceMealSchedule: OverrideSchedule;
  hasScheduleChangedAlready: boolean;
  isBusy: boolean;
  currentDate: any;
}

class RegisterDetails extends PureComponent<any, RegisterDetailsState | any> {
  handleThaaliChange = (event) => {
    this.setState({ thaliSize: event.target.value }); // Assuming you want to update the "zone" state
  };
  handleSectorChange = (event) => {
    this.setState({ zone: event.target.value }); // Assuming you want to update the "zone" state
  };
  constructor(props: any) {
    super(props);
    const {
      match: { params }
    } = this.props;

    this.handleOnQunatityChange = this.handleOnQunatityChange.bind(this);
    this.isScheduleChanged = this.isScheduleChanged.bind(this);
    this.getMealCountForDay = this.getMealCountForDay.bind(this);
    this.state = { currentDate: dateFns.parseISO(params.currentDate) };
    this.handleSectorChange = this.handleSectorChange.bind(this); // Bind handleChange function
    this.handleThaaliChange = this.handleThaaliChange.bind(this); // Bind handleChange function
  }

  isScheduleChanged = (optedScheduleCount: number, updatedCount: number) => {
    return optedScheduleCount !== updatedCount;
  };

  getMealCountForDay = (
    schedule: Schedule | OverrideSchedule,
    selectedDayDate: any
  ) => {
    return schedule[WEEKDAYS[dateFns.getDay(this.state.currentDate)]];
  };

  handleOnQunatityChange = (event: any) => {
    const target = event.target;
    const value = parseInt(target.value); // Parse the value to integer
    this.setState({ numberOfOptedMealForSelectedDay: value });

    // Update cancelOrReduceMealSchedule with the selected quantity
    const updatedSchedule: OverrideSchedule = {
      ...this.state.cancelOrReduceMealSchedule,
      weeklyOverrideSchedule: {
        ...this.state.cancelOrReduceMealSchedule.weeklyOverrideSchedule,
        [WEEKDAYS[dateFns.getDay(this.state.currentDate)]]: value
      }
    };

    this.setState({ cancelOrReduceMealSchedule: updatedSchedule });
  };


  initiateState() {
    if (this.props.mealSchedule) {
      const overrideSchedule = this.props.mealSchedule.overrideSchedules.filter(
        schedule => {
          return dateFns.isWithinInterval(this.state.currentDate, {
            start: new Date(
              schedule.overrideStartDate.split('-')[0],
              schedule.overrideStartDate.split('-')[1] - 1,
              schedule.overrideStartDate.split('-')[2]
            ),
            end: new Date(
              schedule.overrideEndDate.split('-')[0],
              schedule.overrideEndDate.split('-')[1] - 1,
              schedule.overrideEndDate.split('-')[2]
            )
          });
        }
      )[0];
      this.setState({
        cancelOrReduceMealSchedule: {
          overrideStartDate: dateFns.parseISO(
            dateFns.format(this.state.currentDate, 'yyyy-MM-dd', {
              awareOfUnicodeTokens: true
            })
          ),
          overrideEndDate: dateFns.parseISO(
            dateFns.format(this.state.currentDate, 'yyyy-MM-dd', {
              awareOfUnicodeTokens: true
            })
          ),
          weeklyOverrideSchedule: {
            ...(overrideSchedule
              ? overrideSchedule.weeklyOverrideSchedule
              : this.props.mealSchedule.optedSchedule)
          }
        },
        numberOfOptedMealForSelectedDay: overrideSchedule
          ? this.getMealCountForDay(
            overrideSchedule.weeklyOverrideSchedule,
            this.state.currentDate
          )
          : this.getMealCountForDay(
            this.props.mealSchedule.optedSchedule,
            this.state.currentDate
          ),
        hasScheduleChangedAlready: overrideSchedule ? true : false
      });
    }
  }

  componentDidMount() {
    if (this.props.mealSchedule.optedSchedule.MONDAY === null) {
      this.props.getSubscriptionSchedule(this.props.subscriberId);
    }
    if (this.props.schedules && this.props.schedules.length === 0) {
      this.props.getMonthsSchedule();
    }
    this.initiateState();
    this.setState({
      zone: '',
      sectors: {},
      isInProgres: false,
    });
    this.props.getUserProfile(this.props.username);
    this.props.getSectorNames();

  }

  componentDidUpdate(prevProps: any, prevState: RegisterDetailsState) {
    if (
      this.props.schedule !== prevProps.schedule ||
      this.props.mealSchedule !== prevProps.mealSchedule
    ) {
      this.initiateState();
    }
    if (this.props.sectors !== prevProps.sectors) {
      // Update state when sector names are fetched
      this.setState({ sectors: this.props.sectors });
    }
    if (
      this.props.profile !== prevProps.profile
    ) {
      this.setState({
        zone: this.props.profile.zone
          ? this.props.profile.zone
          : '',
        thaliSize: this.props.profile.thaliSize
          ? this.props.profile.thaliSize
          : '',
      });
    }
  }

  render() {
    // Define all options
    const allThaaliOptions = [
      { value: "Regular", label: "Grey (Family of 4+)" },
      { value: "Medium", label: "White (Family of Under 4)" },
      { value: "Single", label: "Blue (Single Person/Student)" }
    ];
    // Filter options based on the value of restrictedThaali is true show Medium and Single and  fmbregistered if it is false, then show only Medium.. 

    const thaaliOptions = this.props.fmbregistered ? allThaaliOptions.filter(option => option.value !== "Regular") : !this.props.fmbregistered ? allThaaliOptions.filter(option => option.value !== "Regular") : allThaaliOptions;


    const updateMealPlanAndNavigate = async () => {
      try {
        const { mealSchedule, subscriberId, history } = this.props;
        const { currentDate, cancelOrReduceMealSchedule, hasScheduleChangedAlready } = this.state;

        const optedMealCount = this.getMealCountForDay(mealSchedule.optedSchedule, currentDate);
        const updatedMealCount = cancelOrReduceMealSchedule
          ? this.getMealCountForDay(cancelOrReduceMealSchedule.weeklyOverrideSchedule, currentDate)
          : null;

        this.setState({ isBusy: true });

        const formattedDate = dateFns.format(currentDate, 'yyyy-MM-dd', { awareOfUnicodeTokens: true });

        if (!isMealCancellationEnabled(formattedDate)) {
          history.goBack();
          return;
        }

        if (hasScheduleChangedAlready) {
          await this.props.deleteOverrideSchedule(subscriberId, formattedDate);
        }

        if (this.isScheduleChanged(optedMealCount, updatedMealCount)) {
          await this.props.addOverrideSchedule(
            subscriberId,
            cancelOrReduceMealSchedule,
            () => {
              this.setState({ isBusy: false });
              history.goBack();
            }
          );
        } else {
          history.goBack();
        }
      } catch (error) {
        console.error("Error updating meal plan:", error);
        this.setState({ isBusy: false });
      }


      this.props.updateSector(
        {
          subscriberId: this.props.username,
          zone: this.state.zone
        }
      );

      this.props.updateThaliSize(
        {
          subscriberId: this.props.username,
          thaliSize: this.state.thaliSize
        },
        () => this.props.nextStep(),
        () => this.setState({ isInProgres: false })
      );
    }


    const buildQuantitySelector = (optedQuantity: number) => {
      const menuItems: any[] = [];

      if (!optedQuantity) {
        return menuItems; // Return an empty array if optedQuantity is falsy
      }

      // Loop to generate radio buttons for each quantity option
      for (let i = optedQuantity; i >= 0; i--) {
        menuItems.push(
          <FormControlLabel
            key={i}
            value={i.toString()}
            control={<Radio color="secondary" checked={this.state.numberOfOptedMealForSelectedDay === i} />}
            label={
              i === 1
                ? `I want ${i} Thali on ${dateFns.format(this.state.currentDate, 'dd-MMM-yyyy', { awareOfUnicodeTokens: true })}`
                : `I want to cancel thali on ${dateFns.format(this.state.currentDate, 'dd-MMM-yyyy', { awareOfUnicodeTokens: true })}`
            }
            className="MenuDetails-quantity-selector-radio-label"
          />
        );
      }
      return menuItems;
    };

    const buildMenuItem = (menuItems: FoodMenuItems[] | null) => {
      return (
        menuItems &&
        menuItems.map((menuItem: FoodMenuItems, index: number) => {
          return (
            <span className="Dashboard-menu-items-each" key={index}>
              {' '}
              &#x2726; {menuItem.itemName}
            </span>
          );
        })
      );
    };

    return (
      <div>
        <Paper className="MenuDetails-header">
          <strong>
            {` Menu For ${dateFns.format(this.state.currentDate, 'dd-MMM-yyy', {
              awareOfUnicodeTokens: true
            })}`}{' '}
          </strong>
        </Paper>
        <React.Fragment>
          <div className="MenuDetails-container">
            <div className="MenuDetails-card-container">
              <Spinner active={this.state.isBusy}>
                <Card className="Card-container">
                  <CardContent>
                    {this.props.selectedDateSchedule &&
                      this.props.selectedDateSchedule.menu &&
                      this.props.selectedDateSchedule.menu.items.length > 0 && (
                        <React.Fragment>
                          <div className="Dashboard-menu-container">
                            <div className="Dashboard-menu-primary-container Dashboard-menu-container_items">
                              <Typography>
                                <strong>Menu</strong>
                              </Typography>
                              <Typography component="div">
                                <div className="Dashboard-menu-items">
                                  {buildMenuItem(
                                    this.props.selectedDateSchedule.menu &&
                                    this.props.selectedDateSchedule.menu.items
                                  )}
                                </div>
                              </Typography>
                            </div>
                          </div>
                          {isMealCancellationEnabled(
                            dateFns.format(
                              this.state.currentDate,
                              'yyyy-MM-dd',
                              {
                                awareOfUnicodeTokens: true
                              }
                            )
                          ) && (
                              <React.Fragment>
                                <div className="MenuDetails-quantity-selector-container">
                                  <RadioGroup
                                    aria-label="position"
                                    name="position"
                                    value={
                                      this.state
                                        .numberOfOptedMealForSelectedDay &&
                                      this.state.numberOfOptedMealForSelectedDay.toString()
                                    }
                                    onChange={this.handleOnQunatityChange}
                                  >
                                    {buildQuantitySelector(
                                      1
                                    )}
                                  </RadioGroup>
                                  <div>
                                    {/* #################################################################################### */}
                                    <Typography
                                      className="col-sm-12 col-lg-12"
                                      component="h1"
                                      variant="h5"
                                      align="center"
                                    >
                                      Sector
                                    </Typography>

                                    <FormControl
                                      margin="normal"
                                      fullWidth
                                    >
                                      <Select
                                        name="sector"
                                        id="sector"
                                        value={this.state.zone || ''} // Provide a default value if this.state.zone is undefined
                                        onChange={this.handleSectorChange}

                                      >
                                        {Array.isArray(this.props.sectors) && this.props.sectors.map((sector: any) => (
                                          <MenuItem key={sector.messageValue} value={sector.messageValue}>
                                            {sector.messageLabel}
                                          </MenuItem>
                                        ))}

                                      </Select>

                                    </FormControl>

                                  </div>
                                  {/* #################################################################################### */}
                                  <Typography
                                    className="col-sm-12 col-lg-12"
                                    component="h1"
                                    variant="h5"
                                    align="center"
                                  >
                                    Thaali Size
                                  </Typography>
                                  <FormControl
                                    margin="normal"
                                    fullWidth
                                  >
                                    <Select
                                      name="thaali"
                                      id="thaali"
                                      value={this.state.thaliSize || ''}
                                      onChange={this.handleThaaliChange}
                                    >
                                      {thaaliOptions.map(option => (
                                        <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                      ))}
                                    </Select>

                                  </FormControl>
                                  {/* #################################################################################### */}
                                </div>
                              </React.Fragment>
                            )}
                          <React.Fragment>
                            <div className="MenuDetails-quantity-action-container">
                              <FormControl margin="dense">
                                <Button
                                  type="submit"
                                  fullWidth
                                  variant="contained"
                                  color="secondary"
                                  onClick={() => updateMealPlanAndNavigate()}
                                >
                                  {isMealCancellationEnabled(
                                    dateFns.format(
                                      this.state.currentDate,
                                      'yyyy-MM-dd',
                                      { awareOfUnicodeTokens: true }
                                    )
                                  )
                                    ? 'Save'
                                    : 'Back'}
                                </Button>
                              </FormControl>
                            </div>
                          </React.Fragment>
                        </React.Fragment>
                      )}
                  </CardContent>
                </Card>
              </Spinner>
            </div>
          </div>
        </React.Fragment>
      </div >
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: any) => {
  return {
    selectedDateSchedule: state.schedules.find(
      schedule =>
        schedule.dailyDate === (ownProps && ownProps.match.params.currentDate)
    ) as Schedule,
    subscriberId: state.authentication.decodedToken.subscriberId,
    username: state.authentication.decodedToken.username,
    mealSchedule: state.mealSchedule as SubscriptionSchedule,
    profile: state.profile,
    sectors: state.content && state.content.sectors ? state.content.sectors : {} // Check if state.content and state.content.sectors are not null
  };
};
const styles = (theme: any) => ({
  fab: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 15,
    marginBottom: 15,
    height: 35,
    width: 35
  },
  card: {
    maxWidth: 400
  },
  media: {
    height: 0,
    paddingTop: '56.25%' // 16:9
  },
  actions: {
    display: 'flex'
  },
  cardcontent: {
    padding: 5,
    marginTop: 5
  }
});

export default requireAuth(
  connect(mapStateToProps, { ...scheduleAction, ...mealscheduleAction, ...contentAction, ...sectorAction, ...profileAction, ...thaliSizeAction })(
    withStyles(styles)(RegisterDetails)
  )
);
