import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import requireAuth from '../../../requireAuth';
import Spinner from '../../Spinner/Spinner';

import { withStyles } from '@material-ui/core/styles';

import { Schedule, AppState, MenuItem, OverrideSchedule } from '../../../type/Type';
import * as scheduleAction from '../../../reducers/scheduleAction';
import * as mealscheduleAction from '../../../reducers/mealscheduleAction';
import * as profileAction from '../../../reducers/profileAction';

import { isMealCancellationEnabled } from '../menuSchedulingUtils'

import Fab from '@material-ui/core/Fab';
import Divider from '@material-ui/core/Divider/Divider';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';

import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBeforeOutlined';
import BlockOutlined from '@material-ui/icons/BlockOutlined';
import RateReviewOutlined from '@material-ui/icons/RateReviewOutlined';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNextRounded';
import Paper from '@material-ui/core/Paper';
import HowToRegIcon from '@material-ui/icons/HowToReg';

import './MenuSchedule.css';

import { parseISO, isWithinInterval, getDay } from 'date-fns';


const dateFns = require('date-fns');

class MenuSchedule extends PureComponent<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      weekStartDate: dateFns.startOfWeek(new Date()),
      activeWeekRelativeToCurrentWeek: 0,
      isBusy: true
    };
  }

  componentDidMount() {
    this.props.getUserProfile(this.props.subscriberId);
    this.props.getMonthsSchedule();
    this.props.getSubscriptionSchedule(this.props.subscriberId);
    this.props.getReviewForUserByDateRange(this.props.subscriberId,
      dateFns.format(
        this.state.weekStartDate,
        'yyyy-MM-dd',
        { awareOfUnicodeTokens: true }
      ),
      dateFns.format(
        dateFns.addDays(this.state.weekStartDate, 7),
        'yyyy-MM-dd',
        { awareOfUnicodeTokens: true }
      ),
    )
    this.setState({ isBusy: false })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.weekStartDate !== prevState.weekStartDate) {
      this.props.getReviewForUserByDateRange(this.props.subscriberId,
        dateFns.format(
          this.state.weekStartDate,
          'yyyy-MM-dd',
          { awareOfUnicodeTokens: true }
        ),
        dateFns.format(
          dateFns.addDays(this.state.weekStartDate, 7),
          'yyyy-MM-dd',
          { awareOfUnicodeTokens: true }
        ),
      )
    }
  }

  render() {
    let WEEKDAYS = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

    const buildMenuItem = (
      menuItems: MenuItem[] | null,
      noMeal: boolean,
      noMealReason: string
    ) => {
      if (noMeal && noMealReason) {
        return <li > {`No Thali : ${noMealReason}`}</li>
      }
      return (
        menuItems &&
        menuItems.map((menuItem: MenuItem, index: number) => {
          return <li key={index}> {menuItem.itemName}</li>;
        })
      );
    };

    const getCorrectWeeklyOverrideValue = (weeklyOverrideSchedule, date) => {
      const dayOfWeekIndex = dateFns.getDay(new Date(date));
      const dayOfWeek = WEEKDAYS[dayOfWeekIndex];
      return weeklyOverrideSchedule[dayOfWeek];
    };

    const isMealCancelledOnDate = (date: string) => {
      const { overrideSchedules } = this.props.mealSchedule;

      const schedule = overrideSchedules.find(schedule =>
        dateFns.isWithinInterval(new Date(date), {
          start: new Date(schedule.overrideStartDate),
          end: new Date(schedule.overrideEndDate)
        })
      );

      if (schedule) {
        const weeklyOverrideValue = getCorrectWeeklyOverrideValue(schedule.weeklyOverrideSchedule, date);
        // Return true if the value is 0, indicating the meal is cancelled
        if (weeklyOverrideValue === 0) {
          return true;
        }
      }

      return false;
    };


    const getWeeklyOverrideValue = (date: string) => {
      const { overrideSchedules } = this.props.mealSchedule;

      const schedule = overrideSchedules.find(schedule =>
        dateFns.isWithinInterval(new Date(date), {
          start: new Date(schedule.overrideStartDate),
          end: new Date(schedule.overrideEndDate)
        })
      );

      if (schedule) {
        const weeklyOverrideValue = getCorrectWeeklyOverrideValue(schedule.weeklyOverrideSchedule, date);

        // Return true if the value is 0, indicating the meal is cancelled
        if (weeklyOverrideValue > 0) {
          return true;
        }
      }

      return false;
    };


    const isReviewEnabled = (reviewDate: string) => {
      const parsedReviewDate = dateFns.parseISO(reviewDate);
      const today = new Date();

      // Check if reviewDate is today
      if (dateFns.isToday(parsedReviewDate)) {
        // Review enabled if current time is past 19:00 (7 PM)
        return dateFns.getHours(today) >= 19;
      } else {
        // Log the result of isMealCancelledOnDate(reviewDate)
        const mealCancelled = isMealCancelledOnDate(reviewDate);
        // console.log('isMealCancelledOnDate:', mealCancelled);

        // Check if reviewDate is not in the future
        const isNotFuture = !dateFns.isFuture(parsedReviewDate);
        // Check if the reviewDate is within the last 5 days
        const isWithinLast5Days = dateFns.differenceInDays(parsedReviewDate, dateFns.addDays(today, -5)) <= 5;

        return isNotFuture && !mealCancelled && isWithinLast5Days;
      }
    };


    const buildMenu = () => {
      return (
        this.props.schedule &&
        this.props.schedule.length > 0 &&
        this.props.schedule.map((day: Schedule, index: number) => {
          const isDatePassed = dateFns.isBefore(dateFns.parseISO(day.dailyDate), new Date());
          const today = new Date();
          const dailyDate = new Date(day.dailyDate);
          const twoDaysBeforeDailyDate = new Date(dailyDate);
          twoDaysBeforeDailyDate.setDate(dailyDate.getDate() - 2);

          const isTwoDaysBeforeDailyDate = today.toDateString() === twoDaysBeforeDailyDate.toDateString();

          const schedule = isMealCancelledOnDate(day.dailyDate);
          const getWeeklyOverride = getWeeklyOverrideValue(day.dailyDate);

          return (

            dateFns.isSameWeek(
              new Date(day.dailyDate),
              this.state.weekStartDate
            ) && (
              <div key={index} className="daily-menu-container">
                <Card className={this.props.classes.card}>
                  <CardActionArea
                    onClick={() => {
                      if ((isReviewEnabled(day.dailyDate) || day.review) && this.props.fmbregistered) {
                        this.props.history.push(`/menu-schedule/review/${day.dailyDate}`);
                      } else if ((!isReviewEnabled(day.dailyDate) || !day.review) && this.props.fmbregistered) {
                        this.props.history.push(`/menu-schedule/details/${day.dailyDate}`);
                      } else if (!isDatePassed && !this.props.fmbregistered && day.fullThali) {
                        this.props.history.push(`/menu-schedule/register/${day.dailyDate}`);
                      }
                    }
                    }
                    disabled={day.noMeal}
                  >
                    <CardHeader
                      title={
                        dateFns.format(
                          dateFns.parseISO(day.dailyDate),
                          'EEEE dd, MMMM',
                          { awareOfUnicodeTokens: true }
                        )}
                      avatar={<CalendarTodayRoundedIcon fontSize="small" />}
                      className={schedule ? "MenuCard-Header-Meal-Cancelled" : getWeeklyOverride ? "MenuCard-Header-Meal-Availed" : ""}

                    />
                    <Divider />
                    <CardContent className={this.props.classes.cardcontent}>
                      <div className="MenuSchedule-content-contianer">
                        <ul>
                          <Typography component="p">
                            {buildMenuItem(
                              day.menu && day.menu.items,
                              day.noMeal,
                              day.noMealReason
                            )}
                          </Typography>
                        </ul>
                        {!day.noMeal &&
                          <div className="MenuSchedule-action-contianer">
                            {!isMealCancelledOnDate(day.dailyDate) && isReviewEnabled(day.dailyDate) && !day.review && this.props.fmbregistered &&
                              <div className="MenuSchedule-action-review-cancel">
                                <span><strong> Review</strong> </span>
                                <span> <RateReviewOutlined /></span>
                              </div>
                            }
                            {console.log(day.dailyDate + ":" + getDay(new Date(day.dailyDate)))}
                            {!isMealCancelledOnDate(day.dailyDate) && isMealCancellationEnabled(day.dailyDate) && this.props.fmbregistered &&
                              !isReviewEnabled(day.dailyDate) && (this.props.mealSchedule.optedSchedule[WEEKDAYS[getDay(new Date(day.dailyDate))]] > 0) &&
                              <div className="MenuSchedule-action-review-cancel">
                                <span><strong>Cancel</strong> </span>
                                <span><BlockOutlined /></span>
                              </div>
                            }
                            {!isDatePassed && !this.props.fmbregistered && day.fullThali && isMealCancellationEnabled(day.dailyDate) && !schedule && !getWeeklyOverride && (
                              <div className="MenuSchedule-action-review-cancel">
                                <span><strong>Register</strong> </span>
                                <span><HowToRegIcon /></span>
                              </div>
                            )}
                            {!this.props.fmbregistered && day.fullThali && isMealCancellationEnabled(day.dailyDate) && getWeeklyOverride && (
                              <div className="MenuSchedule-action-review-cancel">
                                <span><strong>Cancel</strong> </span>
                                <span><BlockOutlined /></span>
                              </div>
                            )}
                          </div>
                        }


                      </div>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>
            )
          );
        })
      );
    };

    const navigatePreviousWeek = () => {
      this.setState({
        weekStartDate: dateFns.addWeeks(this.state.weekStartDate, -1)
      });
    };

    const navigateNextWeek = () => {
      this.setState({
        weekStartDate: dateFns.addWeeks(this.state.weekStartDate, 1)
      });
    };

    return (
      <div>
        <Spinner active={this.state.isBusy}>
          <Paper className="menu-schedule-week-navigator-container">

            <Fab
              onClick={() => navigatePreviousWeek()}
              aria-label="Open Dashboard"
              className={this.props.classes.fab}
            >
              <NavigateBeforeIcon />
            </Fab>
            <div>
              <strong>
                {` Menu for Week of ${dateFns.format(
                  this.state.weekStartDate,
                  'dd MMM',
                  { awareOfUnicodeTokens: true }
                )}`}{' '}
              </strong>
            </div>
            <Fab
              onClick={() => navigateNextWeek()}
              className={this.props.classes.fab}
            >
              <NavigateNextIcon />
            </Fab>
          </Paper>
          {buildMenu()}
        </Spinner>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    subscriberId: state.authentication.decodedToken.subscriberId,
    schedule: state.schedules as Schedule[],
    mealSchedule: state.mealSchedule,
    fmbregistered: state.profile.fmbregistered
  };
};
const styles = (theme: any) => ({
  fab: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 15,
    marginBottom: 15,
    height: 35,
    width: 35
  },
  media: {
    height: 0,
    //paddingTop: '56.25%' // 16:9
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  cardcontent: {
    padding: 5,
    marginTop: 5
  }

});

export default requireAuth(
  connect(mapStateToProps, { ...scheduleAction, ...mealscheduleAction, ...profileAction })(withStyles(styles)(MenuSchedule))
);