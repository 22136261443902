import React, { useEffect } from 'react';
import { getContactInfo } from '../../reducers/contentAction';
import { connect } from 'react-redux';
import { AppState, LabelValue } from '../../type/Type';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import './HelpContact.css';

const HelpContact = (props) => {
  useEffect(() => {
    if (!props.helpContacts) {
      props.getContactInfo(() => { console.log('Error Fetching Contact') });
    }
  }, [props.helpContacts]);

  return (
    <div>
      {props.helpContacts &&
        props.helpContacts.map((contactInfo: LabelValue, index) =>
          <Card key={index} className="Card-container Dashboard-container HelpContacts-container">
            <CardContent>
              <React.Fragment>
                <Typography>
                  <div className="HelpContacts-details">
                    <span>
                      <strong>{contactInfo.messageLabel}</strong>
                    </span>
                    <span>
                      {contactInfo.messageValue.split("|")[0]}
                    </span>
                    <span>
                      <WhatsAppIcon fontSize="small" />
                      {contactInfo.messageValue.split("|")[1]}
                    </span>
                  </div>
                </Typography>
              </React.Fragment>
            </CardContent>
          </Card>
        )
      }

      <Card className="Card-container Dashboard-container HelpContacts-container">
        <CardContent>
          <React.Fragment>
            <Typography>
              <div className="HelpContacts-details">
                <span>
                  <strong>Sector Contact Information</strong>
                </span>
                <span className="table-wrapper">
                  <table className="contact-table">
                    <thead>
                      <tr>
                        <th>Sector Name</th>
                        <th>Address</th>
                        <th>Depot Contact</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Brampton North West</td>
                        <td>13 Woodhaven Dr. Brampton L7A 1Y6</td>
                        <td>Huzaifa Alibhai</td>
                      </tr>
                      <tr>
                        <td>Badri Sector</td>
                        <td>3466 Sawmill Valley Drive, Mississauga, ON L5H 4B2</td>
                        <td>Nisreen Hazari/Mustafa Kubbawala</td>
                      </tr>
                      <tr>
                        <td>Burhani Sector</td>
                        <td>6635 Campbellville Rd, Mississauga, ON L5N 2W9</td>
                        <td>Murtaza Gandhi</td>
                      </tr>
                      <tr>
                        <td>Ezzi Sector</td>
                        <td>6701 Lisgar Dr Mississauga, ON L5N 6S8</td>
                        <td>Shabbir Vora</td>
                      </tr>
                      <tr>
                        <td>Fakhri Sector</td>
                        <td>676 Thompson Rd S, Milton, ON L9T 5W8</td>
                        <td>Zohair Jafferji</td>
                      </tr>
                      <tr>
                        <td>Hakimi Sector</td>
                        <td>Unit 144, 1250 Mississauga Valley Boulevard, Mississauga</td>
                        <td>Moiz Bagasrawala</td>
                      </tr>
                      <tr>
                        <td>Husami Sector</td>
                        <td>11 Bergamot Ave, Apt# 419, Etobicoke, ON M9W 1W3</td>
                        <td>Amir Javeri</td>
                      </tr>
                      <tr>
                        <td>Imadi Sector</td>
                        <td>88 Flurry Circle, Brampton, ON L6X 0B7</td>
                        <td>Mustafa Ali</td>
                      </tr>
                      <tr>
                        <td>Jamali Sector</td>
                        <td>2756. Peacock Dr, Mississauga, ON L5M 5R5</td>
                        <td>Hounaïd Kakal</td>
                      </tr>
                      <tr>
                        <td>Najmi Sector</td>
                        <td>51 Bruce Beer Drive, Brampton ON, Canada L6V 2W7</td>
                        <td>Kaizar Mithaiwala</td>
                      </tr>
                      <tr>
                        <td>Qutbi Sector</td>
                        <td>3249 Stoney Crescent, Mississauga, ON L5M 0V4</td>
                        <td>Hussain Kharodawala</td>
                      </tr>
                      <tr>
                        <td>Saifi Sector</td>
                        <td>56 Cedar Lake Cres, Brampton, ON L6Y 0P9</td>
                        <td>Quresh Motiwala</td>
                      </tr>
                      <tr>
                        <td>Shujai Sector</td>
                        <td>1069 Charminstèr Crescent Mississauga ON L5V 1R1</td>
                        <td>Khozema Saherawala</td>
                      </tr>
                      <tr>
                        <td>Wajihi Sector</td>
                        <td>363 Winfield Terrace, Mississauga ON</td>
                        <td>Moiz Khambati</td>
                      </tr>
                      <tr>
                        <td>Zaini Sector</td>
                        <td>4633 Glen Erin Drive, Mississauga, ON L5M 0Y6</td>
                        <td>Farzana Lakdawala</td>
                      </tr>
                      <tr>
                        <td>Mohammedi (Waterloo/Kitchener/Cambridge)</td>
                        <td>101-121 University Ave E, Waterloo, ON N2J 4J1</td>
                        <td>Mufaddal Shaakir</td>
                      </tr>
                      <tr>
                        <td>Hashemi (Guelph)</td>
                        <td>734 Scottsdale Dr, Guelph, ON N1G 3L3</td>
                        <td>Mufaddal Shaakir</td>
                      </tr>
                      <tr>
                        <td>Kalimi (Burlington/Hamilton)</td>
                        <td>47 Fusilier Dr, Hamilton, ON L9B 2E5</td>
                        <td>Mufaddal Shaakir</td>
                      </tr>
                    </tbody>
                  </table>
                </span>
              </div>
            </Typography>
          </React.Fragment>
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    helpContacts: state.content && state.content.helpContacts
  };
};

export default connect(mapStateToProps, { getContactInfo })(HelpContact);
