import React, { Component, useState } from 'react';
import * as profileAction from '../../../reducers/profileAction';
import * as sectorAction from '../../../reducers/sectorAction';
import * as thaliSizeAction from '../../../reducers/thaliSizeAction';

import { AppState } from '../../../type/Type';
import PersonIcon from '@material-ui/icons/Person';
import Spinner from './../../Spinner/Spinner';
import FormValidator from '../../../util/FormValidator';
import requireAuth from '../../../requireAuth';
import { connect } from 'react-redux';

import {
  Paper,
  CssBaseline,
  Avatar,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  Button,
  Select,
  MenuItem
} from '@material-ui/core';
import './ProfileInfo.css';
import { trim } from 'validator';
import * as contentAction from '../../../reducers/contentAction'; // Import the content action creator

class ProfileInfo extends Component<any, any> {
  profileValidator: FormValidator;

  constructor(props: any) {
    super(props);

    this.profileValidator = new FormValidator([
      {
        field: 'postalCode',
        method: 'matches',
        args: [/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/], // args is an optional array of arguements that will be passed to the validation method
        validWhen: true,
        message: 'That is not a valid Postal code.'
      },
      {
        field: 'firstName',
        method: 'isEmpty',
        validWhen: false,
        message: 'Please enter your First Name.'
      },
      {
        field: 'lastName',
        method: 'isEmpty',
        validWhen: false,
        message: 'Please enter your First Name.'
      },
      {
        field: 'email',
        method: 'isEmail',
        validWhen: true,
        message: 'Please enter valid Email Address.'
      },
      {
        field: 'streetName',
        method: 'isEmpty',
        validWhen: false,
        message: 'Please enter your Street Name.'
      },
      {
        field: 'city',
        method: 'isEmpty',
        validWhen: false,
        message: 'Please enter your City.'
      }
    ]);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputChangeAllowNoSpace = this.handleInputChangeAllowNoSpace.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSectorChange = this.handleSectorChange.bind(this); // Bind handleChange function
    this.handleThaaliChange = this.handleThaaliChange.bind(this); // Bind handleChange function
  }

  handleInputChange(event: any) {
    console.log(event.target.value);
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  handleThaaliChange = (event) => {
    this.setState({ thaliSize: event.target.value }); // Assuming you want to update the "zone" state
  };
  handleSectorChange = (event) => {
    this.setState({ zone: event.target.value }); // Assuming you want to update the "zone" state
  };

  handleInputChangeAllowNoSpace(event: any) {
    const target = event.target;
    const value = target.value.replace(/\s/g, '');
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event: any) {
    event.preventDefault();
    const validation = this.profileValidator.validate(this.state);
    this.setState({ validation }); // Update the validation state
    if (validation.isValid) {
      this.setState({ isInProgres: true });
      this.props.updateUserProfile(
        {
          username: this.props.username,
          mobileNumber: this.state.mobileNumber,
          primaryAddress: {
            streetNumber: this.state.streetNumber,
            streetName: this.state.streetName,
            postalCode: this.state.postalCode,
            city: this.state.city,
            province: this.state.province,
            country: this.state.country
          },
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email: this.state.email,
        }
      );

      this.props.updateSector(
        {
          subscriberId: this.props.username,
          zone: this.state.zone
        }
      );

      this.props.updateThaliSize(
        {
          subscriberId: this.props.username,
          thaliSize: this.state.thaliSize
        },
        () => this.props.nextStep(),
        () => this.setState({ isInProgres: false })
      );
    }
  }

  back = (e: any) => {
    e.preventDefault();
    this.props.prevStep();
  };

  componentDidMount() {
    this.setState({
      firstName: '',
      lastName: '',
      mobileNumber: '',
      email: '',
      streetName: '',
      streetNumber: '',
      postalCode: '',
      city: '',
      zone: '',
      sectors: {},
      isInProgres: false,
      validation: this.profileValidator.valid(),
      restrictedThaali: false
    });
    this.props.getUserProfile(this.props.username);
    this.props.getSectorNames(); // Call the action creator to fetch sector names

  }

  componentDidUpdate(prevProps: any) {
    if (this.props.sectors !== prevProps.sectors) {
      // Update state when sector names are fetched
      this.setState({ sectors: this.props.sectors });
    }


    if (
      this.props.profile !== prevProps.profile &&
      this.props.profile.primaryAdress == null
    ) {
      this.setState({
        firstName: this.props.profile.firstName,
        lastName: this.props.profile.lastName,
        mobileNumber: this.props.profile.mobileNumber,
        email: this.props.profile.email ? this.props.profile.email : '',
        streetName: this.props.profile.primaryAddress.streetName
          ? this.props.profile.primaryAddress.streetName
          : '',
        streetNumber: this.props.profile.primaryAddress.streetNumber
          ? this.props.profile.primaryAddress.streetNumber
          : '',
        postalCode: this.props.profile.primaryAddress.postalCode
          ? this.props.profile.primaryAddress.postalCode
          : '',
        city: this.props.profile.primaryAddress.city
          ? this.props.profile.primaryAddress.city
          : '',
        province: this.props.profile.primaryAddress.province
          ? this.props.profile.primaryAddress.province
          : '',
        country: this.props.profile.primaryAddress.country
          ? this.props.profile.primaryAddress.country
          : '',
        zone: this.props.profile.zone
          ? this.props.profile.zone
          : '',
        thaliSize: this.props.profile.thaliSize
          ? this.props.profile.thaliSize
          : '',
        restrictedThaali: this.props.profile.restrictedThaali
          ? this.props.profile.restrictedThaali
          : false,

      });
    }
  }

  render() {
    const currentDate = new Date();
    const currentDay = currentDate.getDay(); // 0 for Sunday, 1 for Monday, etc.
    const currentHour = currentDate.getHours();

    // Define all options
    const allThaaliOptions = [
      { value: "Regular", label: "Grey (Family of 4+)" },
      { value: "Medium", label: "White (Family of Under 4)" },
      { value: "Single", label: "Blue (Single Person/Student)" }
    ];
    // Filter options based on the value of restrictedThaali is true show Medium and Single and  fmbregistered if it is false, then show only Medium.. 

    const thaaliOptions = this.props.profile.restrictedThaali && this.props.profile.fmbregistered ? allThaaliOptions.filter(option => option.value !== "Regular") : !this.props.profile.fmbregistered ? allThaaliOptions.filter(option => option.value !== "Regular") : allThaaliOptions;

    return (

      <div>
        {this.state != null && (
          <Spinner active={this.props.isBusyCommunicating}>
            <div className="container-main row">
              <Paper className="UserProfile-paper col-sm-12  col-lg-5">
                <CssBaseline />
                <Grid container justify="space-evenly" alignItems="center">
                  <Avatar>
                    <PersonIcon />
                  </Avatar>
                  {/* #################################################################################### */}
                  <Typography
                    className="col-sm-12 col-lg-12"
                    component="h1"
                    variant="h5"
                    align="center"
                  >
                    Profile
                  </Typography>
                  <form onSubmit={this.handleSubmit}>
                    <FormControl margin="normal" required fullWidth>
                      <InputLabel htmlFor="firstName"> First Name :</InputLabel>
                      <Input
                        type="text"
                        required
                        autoFocus
                        name="firstName"
                        id="firstName"
                        value={this.state.firstName}
                        onChange={this.handleInputChange}
                        error={this.state.validation.firstName.isInvalid}
                      />
                      <FormHelperText>
                        {this.state.validation.firstName.message}
                      </FormHelperText>
                    </FormControl>
                    <FormControl margin="normal" required fullWidth>
                      <InputLabel htmlFor="lastName">Last Name:</InputLabel>
                      <Input
                        type="text"
                        required
                        autoFocus
                        name="lastName"
                        id="lastName"
                        value={this.state.lastName}
                        onChange={this.handleInputChange}
                        error={this.state.validation.lastName.isInvalid}
                      />
                      <FormHelperText>
                        {this.state.validation.lastName.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl margin="normal" required fullWidth>
                      <InputLabel htmlFor="mobileNumber">Mobile :</InputLabel>
                      <Input
                        type="text"
                        autoFocus
                        name="mobileNumber"
                        id="mobileNumber"
                        placeholder="11111111"
                        value={this.state.mobileNumber}
                        onChange={this.handleInputChange}
                      />
                      <FormHelperText />
                    </FormControl>

                    <FormControl margin="normal" required fullWidth>
                      <InputLabel htmlFor="Email">Email</InputLabel>
                      <Input
                        required
                        name="email"
                        id="email"
                        placeholder="Email"
                        value={this.state.email}
                        onChange={this.handleInputChange}
                        error={this.state.validation.email.isInvalid}
                      />
                      <FormHelperText>
                        {this.state.validation.email.message}
                      </FormHelperText>
                    </FormControl>
                    {/* #################################################################################### */}
                    <Typography
                      className="col-sm-12 col-lg-12"
                      component="h1"
                      variant="h5"
                      align="center"
                    >
                      Address
                    </Typography>
                    <FormControl
                      margin="normal"
                      className="col-xs-12 col-sm-6  col-lg-6  "
                      required
                    >
                      <InputLabel htmlFor="streetName">Street Name</InputLabel>
                      <Input
                        id="streetName"
                        name="streetName"
                        placeholder="Please enter your street name"
                        value={this.state.streetName}
                        onChange={this.handleInputChange}
                        error={this.state.validation.streetName.isInvalid}
                      />
                      <FormHelperText>
                        {this.state.validation.streetName.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl
                      margin="normal"
                      className="col-xs-12 col-sm-6  col-lg-6  "
                      required
                    >
                      <InputLabel htmlFor="postalCode">Postal Code</InputLabel>
                      <Input
                        id="postalCode"
                        name="postalCode"
                        placeholder="Please enter your postal code"
                        value={this.state.postalCode
                          ? this.state.postalCode.replace(/\s/g, '')
                          : this.state.postalCode}
                        onChange={this.handleInputChangeAllowNoSpace}
                        error={this.state.validation.postalCode.isInvalid}

                      />
                      <FormHelperText>
                        {this.state.validation.postalCode.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl
                      margin="normal"
                      required
                      fullWidth
                    >
                      <InputLabel htmlFor="city">City</InputLabel>
                      <Input
                        id="city"
                        placeholder="Enter city"
                        value={this.state.city}
                        onChange={this.handleInputChange}
                        error={this.state.validation.city.isInvalid}
                      />
                      <FormHelperText>
                        {this.state.validation.city.message}
                      </FormHelperText>
                    </FormControl>
                    {/* #################################################################################### */}
                    <Typography
                      className="col-sm-12 col-lg-12"
                      component="h1"
                      variant="h5"
                      align="center"
                    >
                      Sector
                    </Typography>

                    <FormControl
                      margin="normal"
                      fullWidth
                    >
                      <Select
                        name="sector"
                        id="sector"
                        value={this.state.zone || ''} // Provide a default value if this.state.zone is undefined
                        onChange={this.handleSectorChange}
                        disabled={
                          // Disable the Select component between 12pm and 7pm from Monday to Friday
                          (currentDay >= 1 && currentDay <= 5 && currentHour >= 12 && currentHour < 19)
                        }

                      >
                        {Array.isArray(this.props.sectors) && this.props.sectors.map((sector: any) => (
                          <MenuItem key={sector.messageValue} value={sector.messageValue}>
                            {sector.messageLabel}
                          </MenuItem>
                        ))}

                      </Select>

                    </FormControl>
                    <p style={{ display: 'block', color: 'red' }}>
                      <u>Note:</u> Sector can only be changed between 7pm - 11:59am from Monday to Friday
                    </p>
                    {/* #################################################################################### */}
                    <Typography
                      className="col-sm-12 col-lg-12"
                      component="h1"
                      variant="h5"
                      align="center"
                    >
                      Thaali Size
                    </Typography>
                    <FormControl
                      margin="normal"
                      fullWidth
                    >
                      <Select
                        name="thaali"
                        id="thaali"
                        value={this.state.thaliSize || ''}
                        onChange={this.handleThaaliChange}
                        disabled={currentDay !== 0 && currentDay !== 6} // Disable the Select component if it's not Saturday or Sunday
                      >
                        {thaaliOptions.map(option => (
                          <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                        ))}
                      </Select>

                      <p style={{ display: 'block', color: 'red' }}>
                        <u>Note:</u> Thaali Size can only be changed on Saturday and Sunday
                      </p>

                    </FormControl>
                    {/* #################################################################################### */}
                    <FormControl fullWidth>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                      >
                        Save
                      </Button>
                    </FormControl>
                  </form>
                </Grid>
              </Paper>
            </div>
          </Spinner>
        )
        }
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    username: state.authentication.decodedToken.username,
    profile: state.profile,
    sectors: state.content && state.content.sectors ? state.content.sectors : {} // Check if state.content and state.content.sectors are not null
  };
};

export default requireAuth(
  connect(mapStateToProps, { ...profileAction, ...contentAction, ...sectorAction, ...thaliSizeAction })(ProfileInfo));