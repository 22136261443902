import React, { Component } from 'react';
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';
import './App.css';
import AppRouter from './components/AppRouter/AppRouter';
import axios from 'axios';
import { TOKEN_API_ENPOINT } from './api/API';
import CacheLoading from './CacheLoading';


class App extends Component {
  componentDidMount() {
    axios.get(`${TOKEN_API_ENPOINT}/hello`);
  }

  render() {
    const isProduction = process.env.NODE_ENV === 'production';
    return (
      <div className="container-fluid">
        <CacheBuster
          currentVersion={version}
          isEnabled={isProduction} //If false, the library is disabled.
          isVerboseMode={false} //If true, the library writes verbose logs to console.
          loadingComponent={<CacheLoading />} //If not pass, nothing appears at the time of new version check.
          metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
        >
          <AppRouter />
        </CacheBuster>
      </div>
    );
  }
}

export default App;
